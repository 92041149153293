@import url('https://fonts.googleapis.com/css2?family=Lalezar &family=Poppins&display=swap');

.third_page {
    background: url("../assets/img3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

}