@import url('../../node_modules/font-awesome/css/font-awesome.min.css');


.navbaritems {
    background-color: transparent;
    height: 80px;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    padding: 1rem;
}

.navbar-logo img {
    height: 40px;
    margin-left: 250px;
    cursor: pointer;

}

nav {
    margin-left: 850px;
}

.menu-active {
    color: #40A7A4;
}


nav a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Martel Sans';
    margin: 0 2rem;
    text-decoration: none;
}

nav a:hover {
    color: rgb(64, 167, 164);
    text-shadow: 1px 1px rgb(0, 0, 0);
    transition: 1s;
}



.nav-btn {
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
}

@media only screen and (max-width:1661px) {
    nav {
        margin-left: 650px;

    }
}

@media only screen and (max-width:1451px) {
    nav {
        margin-left: 550px;

    }
}

@media only screen and (max-width:1351px) {
    nav {
        margin-left: 250px;

    }
}

@media only screen and (max-width:1084px) {
    nav {
        margin-left: 150px;
        transition: 1s;
    }
}

@media only screen and (max-width:965px) {

    #nav-open-btn {
        margin-left: 600px;
    }


    .nav-btn {
        visibility: visible;
        opacity: 1;
        font-size: 30px;
        color: white;

        margin-bottom: 10px;
    }

    .nav-open-bt {
        margin-left: 2000px;
    }

    .navbar-logo img {
        height: 35px;
        margin-left: 5px;
    }

    nav {
        position: fixed;
        top: 0;
        right: 0px;
        width: 100%;
        height: 900px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: transparent;
        transition: 1s;
        transform: translateY(-1000px);

    }

    .responsive_nav {
        transform: none;
        background-color: #111111;
    }

    .nav-close-btn {


        font-size: 35px;
        color: white;
    }

    nav a {
        font-size: 1.5rem;
        color: white;
    }

    nav a:hover {
        color: rgb(64, 167, 164);
    }
}

@media only screen and (max-width:745px) {
    #nav-open-btn {
        margin-left: 250px;
    }
}

@media only screen and (max-width:415px) {
    #nav-open-btn {
        margin-left: 50px;
    }
}