@import url('https://fonts.googleapis.com/css2?family=Lalezar &family=Poppins&display=swap');



.table-container {
    border-collapse: separate; /* Change to separate to allow border-radius to work */
    border-spacing: 0; /* Remove any default spacing between cells */
    padding: 1rem;
    background-color: #080200;
    color: #fff;
    border-radius: 10px; /* Rounded corners for the entire table container */
    overflow: hidden; /* Ensures the rounded corners work */
    font-family: "Electrolize", sans-serif;
}

table {
    width: 100%;
    border-collapse: separate; /* Ensure border-collapse is separate */
    border-radius: 10px; /* Rounded corners for the table */
    background-color: #2B1C03;
}

thead th {
    border-top-left-radius: 10px; /* Rounded corner on the top left */
    border-top-right-radius: 10px; /* Rounded corner on the top right */
}

tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Rounded corner on the bottom left */
}

tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Rounded corner on the bottom right */
}

th, td {
    padding: 0.5rem;
    font-size: 12px;
    text-align: left;
    border-bottom: 1px solid #CB6B18;
    background-color: #2B1C03;
}

th {
    cursor: pointer;
    /* position: relative;  */
    color: #F78A27;
}

th span {
    margin-left: 5px;
}

h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.loading,
.error {
    text-align: center;
    font-size: 1.5em;
    margin-top: 20px;
    font-family: "Electrolize", sans-serif;
}

.refresh-button {
    position: relative;
    padding: 0.5rem 1rem;
    background-color: #F8861E;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 10px 10px 0px;
    border-radius: 4px;
    font-family: "Electrolize", sans-serif;
}

.refresh-button .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.toppers-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (max-width: 768px) {

    body {
        display: flex;
    }

    .table-container {
        padding-left: 1rem;
    }

    .refresh-button {
        padding: 5px 10px;
        font-size: 12px;
        font-family: "Electrolize", sans-serif;
    }

    td span{
        display: flex;
        max-width: 80px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    /* table, thead, tbody, th, td, tr {
      display: block;
    }
  
    thead {
      display: none;
    }
  
    tr {
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.9);
    }
  
    td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #ddd;
      border-radius: 8px;
    }
  
    td:last-child {
      border-bottom: none;
    }
  
    td::before {
      content: attr(data-label);
      font-weight: bold;
      flex-basis: 40%;
      text-align: left;
      padding-right: 10px;
    }
  
    tr:hover {
      background-color: rgba(0, 0, 0, 0.05);
    } */
}