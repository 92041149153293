@import url('https://fonts.googleapis.com/css2?family=Lalezar &family=Poppins&display=swap');

.gamepage {
    background: url("../assets/gamebg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 150px;

}

.gamepage h1,
h3 {
    color: #F8861E;
    font-family: "Electrolize", sans-serif;
    font-weight: 400;
    text-align: center;
}

h1 {
    font-size: 32px;
}

.gametitle{
    width: 80%;
}

.button-container{
    display: flex;
    justify-content: space-between;
    width: 300px;
}
