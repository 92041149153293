.main {
    margin-top: 192px;
    text-align: center;
    width: 952px;
    height: 300px;
}

.pageheading {
    background-color: transparent;
    /* margin: 195px 0 30px; */
    text-align: center;

}

.pageheading h1 {
    color: white;
    font-size: 48px;
    font-weight: 400;
    font-family: 'Lalezar', cursive;
    text-shadow: 4px 4px black;
    text-align: center;
}

.dcod {
    color: rgb(65, 154, 167);
    padding: 0.5rem;
}

.pageheading h6 {
    font-family: 'Martel Sans', sans-serif;
    color: rgb(206, 205, 205);
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 9px;
    font-weight: 50;
    margin-top: 2rem;
}


.content p {
    color: #A3A3A3;
    font-family: Martel Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 2rem;
}

@media screen and (max-width: 767px) {
    .content p {
        font-size: 14px;
        line-height: 19px;
        margin-top: 1rem;
    }

    .pageheading h6 {
        width: 310px;
        font-size: 12px;
    }

    .pageheading h1 {
        word-wrap: break-word;
        line-height: 42px;
    }

    .main {
        margin-top: 80px;
        text-align: center;
        width: 350px;
        height: 332px;
    }

    .welcome_page {
        background-size: auto 120%;
    }

}